<template>
  <v-dialog
    overlay-color="primary"
    overlay-opacity=".97"
    persistent
    :fullscreen="this.$vuetify.breakpoint.smAndUp  ? false: true"
    :max-width="'60%'"
    v-model="bActive"
    v-if="bActive">
    <v-card>
      <v-layout
        row
        justify-center>
        <v-container>
          <v-layout
            style="margin-top: 10px; margin-bottom: 10px"
            row
            justify-center>
            <p
              style="font-size: 20px !important; font-weight: normal !important"
              class="section_titles"
              v-text="'Editar imágenes de un proyecto'"
            />
          </v-layout>
          <v-layout
            column
            align-center
            v-if="bRefreshed">
            <v-flex
              v-if="edit_urls.length > 0"
              xs12
              sm12
              v-for="(img, i) in edit_urls"
              :key="i">
              <v-card
                hover
                class="project_image"
                :img="img">
              </v-card>
              <v-layout
                row
                justify-center>
                <v-btn
                  @click="moveImgFront(i)"
                  :disabled="i == 0"
                  class="image_actions"
                  color="primary"
                  small
                  fab>
                  <v-icon>
                    mdi-arrow-collapse-up
                  </v-icon>
                </v-btn>
                <v-btn
                  @click="moveImgBack(i)"
                  :disabled="i == edit_urls.length - 1"
                  class="image_actions"
                  color="primary"
                  small
                  fab>
                  <v-icon>
                    mdi-arrow-collapse-down
                  </v-icon>
                </v-btn>
                <v-btn
                  @click="deleteImage(i)"
                  class="image_actions"
                  color="primary"
                  small
                  fab>
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-layout>
            </v-flex>
            <p
              style="font-size: 15px !important; font-weight: normal !important"
              class="section_titles">
              No hay imágenes disponibles
            </p>
          </v-layout>
          <v-layout
            style="margin-top: 10px; margin-bottom: 10px"
            row
            justify-center>
            <v-layout
              row
              justify-center
              style="max-width: 50%">
              <v-btn
                @click="close_dialog"
                large
                dark
                color="primary">
                Cancelar
              </v-btn>
              <v-spacer/>
              <v-btn
                outlined
                @click="confirm_edit()"
                large
                dark
                color="primary">
                Confirmar
              </v-btn>
            </v-layout>
          </v-layout>
        </v-container>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    bRefreshed: true,
    edit_urls: [],
    load_confirm: false,
    tempImg: []
  }),
  props: {
    urls: Array,
    project: Object,
    usr_token: String,
    bActive: Boolean
  },
  watch: {
    bActive: function () {
      if (this.bActive) {
        this.edit_urls = this.urls
      }
    }
  },
  methods: {
    close_dialog () {
      this.close()
      this.update()
    },
    close () {
      this.$emit('close')
    },
    update () {
      this.$emit('update')
    },
    deleteImage (i) {
      this.edit_urls.splice(i, 1)
      this.project.images.splice(i, 1)
    },
    moveImgFront (i) {
      // move image upward
      // project being edited is stored in this.project ( i + 1 )
      this.bRefreshed = false
      var tempImage = this.edit_urls[i]
      this.edit_urls[i] = this.edit_urls[i - 1]
      this.edit_urls[i - 1] = tempImage
      // edit project images
      var tempImg = this.project.images[i]
      this.project.images[i] = this.project.images[i - 1]
      this.project.images[i - 1] = tempImg
      // refresh images shown
      this.bRefreshed = true
    },
    moveImgBack (i) {
      // move image downward
      // project being edited is stored in this.project ( i - 1 )
      this.bRefreshed = false
      var tempImage = this.edit_urls[i]
      this.edit_urls[i] = this.edit_urls[i + 1]
      this.edit_urls[i + 1] = tempImage
      // edit project images
      var tempImg = this.project.images[i]
      this.project.images[i] = this.project.images[i + 1]
      this.project.images[i + 1] = tempImg
      // refresh images shown
      this.bRefreshed = true
    },
    confirm_edit () {
      this.load_confirm = true
      // var body = new URLSearchParams()
      // body.append('images', this.project.images)
      var project = {
        images: this.project.images
      }
      db.put(`${uri}/api/v1/projects/` + this.project._id, project, {
        headers: {
          'Authorization': this.usr_token
        }
      })
        .then((response) => {
          this.load_confirm = false
          this.$store.commit('toggle_alert', {color: 'success', text: "Se actualizaron las imágenes correctamente."})
          this.close()
          this.update()
        })
        .catch((error) => {
          this.load_confirm = false
          this.$store.commit('toggle_alert', {color: 'fail', text: error.response.data.message})
        })
    },
  }
}
</script>
<style
  scoped>
.image_actions {
  margin: 10px
}
.project_image {
  margin: 5%;
  min-width: 350px;
  min-height: 350px
}
</style>
